import React, {useEffect, useState} from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
// custom
import Layout from "../../components/common/layout";
// Api
import {getContentList} from "../../services/contentservice";
// third party
import toast from "react-hot-toast";
import {marked} from "marked";
import {Container, Row} from "react-bootstrap";

const PrivacyAndPolicy = () => {
    const [privacyNpolicy, setPrivacyNpolicy] = useState();

    /**
     * @function fetchContentList
     * @param
     * @description get content List
     */
    const fetchContentList = async () => {
        try {
            const result = await getContentList();
            setPrivacyNpolicy(result?.data?.data?.privacy_policy);
        } catch (error) {
            toast.error("Something went wrong");
        }
    };

    useEffect(() => {
        fetchContentList();
        // eslint-disable-next-line
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Parivacy-And-Policy</title>
            </Helmet>
            <Layout>
                <Container className="px-0 px-sm-5 my-5">
                    <Row className="px-5 mx-0">
                        {privacyNpolicy && (
                            <div dangerouslySetInnerHTML={{__html: marked(privacyNpolicy)}} />
                        )}
                    </Row>
                </Container>
            </Layout>
        </HelmetProvider>
    );
};

export default PrivacyAndPolicy;
